export const darkTheme = {
    bg: "#15171E",
    bgLight: "#1C1E27",
    bgDark: "#15171E",
    primary: "#007AFF",
    secondary: "#9747FF",
    navbar: "#1C1E27",
    arrow: "#AFAFB5",
    menu_primary_text: "#F2F3F4",
    menu_secondary_text: "#b1b2b3",
    text_primary: "#F2F3F4",
    text_secondary: "#b1b2b3",
    card: "#1C1E27",
    card_light: "#191924",
    disabled: "#b1b2b3",
    shadow: "#00000060",
    white: "#FFFFFF",
    black: "#000000",
    green: "#12ff75",
    yellow: "#ffcc00",
    red: "#ef5350",
    orange: "#F7AD63",
  };
  
  export const lightTheme = {
    bg: "#bfc0c0",
    bgLight: "#FFFFFF",
    primary: "#2D3142",
    secondary: "#EF8354",
    disabled: "#b1b2b3",
    navbar: "#bfc0c0",
    arrow: "#2D3142",
    menu_primary_text: "#F2F3F4",
    menu_secondary_text: "#b1b2b3",
    table_header: "#242445",
    text_primary: "#2D3142",
    text_secondary: "#4d4c4c",
    card: "#FFFFFF",
    black: "#000000",
    white: "#FFFFFF",
    shadow: "#00000020",
    green: "#00ff6a",
    yellow: "#EF8354",
    red: "#ef5350",
    orange: "#F7AD63",
  };